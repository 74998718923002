import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import {
  typography,
  color,
  space,
  fontSize,
  fontFamily,
  width,
} from "styled-system";

interface DefaultProps {
  fontSize?: {};
  width?: {};
  mt?: {};
  mb?: {};
  mr?: {};
  ml?: {};
  px?: {};
  py?: {};
  pt?: {};
  pb?: {};
}

export const FooterWrapper = styled.footer`
  position: relative;
  overflow: hidden;
  margin-top: 120px;

  @media (max-width: 1200px) {
    margin-top: 90px;
  }
  @media (max-width: 990px) {
    margin-top: 90px;
  }
  @media (max-width: 575px) {
    margin-top: 60px;
  }
`;

// Menu
export const MenuWrapper = styled("div")<DefaultProps>`
  position: relative;
  ${space}
  background-color: ${themeGet("colors.blue")}
`;

export const MenuLogo = styled("div")<DefaultProps>`
  ${space}

  .footer-logo {
    display: block;
    width: 150px;

    @media (max-width: 575px) {
      width: 120px;
    }
  }
`;

export const MenuContent = styled("div")<DefaultProps>`
  ${space}
`;

// Copyright
export const CopyrightWrapper = styled("div")<DefaultProps>`
  position: relative;
  ${space}
  text-align: center;
  background-color: ${themeGet("colors.white")};
`;

export const CopyrightText = styled("p")<DefaultProps>`
  ${space}
  ${fontSize}
  font-weight: bold;
`;

export const Text = styled("p")<DefaultProps>`
  display: block;
  font-size: 13px;
  font-weight: bold;
  ${space}
`;

export const LinkText = styled("a")<DefaultProps>`
  display: block;
  color: #333;
  font-size: 10px;

  &:hover,
  &:link,
  &:visited {
    color: #333;
  }
  ${space}
`;
