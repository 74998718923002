import * as React from "react";
import { FooterWrapper } from "./style";
import Menu from "./Menu";
import Copyright from "./Copyright";
import FooterMedia from "./FooterMedia";

type FooterProps = {};

const Footer: React.FunctionComponent<FooterProps> = ({}) => {
  return (
    <FooterWrapper>
      <FooterMedia />
      {/* <Menu /> */}
      <Copyright />
    </FooterWrapper>
  );
};

export default Footer;
