import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const ScrollUpButtonWrapper = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: ${themeGet('colors.blue')};
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  transition: 0.15s ease-in-out;
  @media (max-width: 767px) {
    display: none;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export default ScrollUpButtonWrapper;
