import * as React from "react";
import { CopyrightWrapper, CopyrightText, Text, LinkText } from "./style";

type DefaultProps = {};

const Copyright: React.FC<DefaultProps> = ({}) => {
  return (
    <CopyrightWrapper pt={[6, 7]} pb={[3, 4]}>
      <CopyrightText fontSize={[12, 15]}>
        &copy; {new Date().getFullYear()} TENJIN DERMATOLOGY
      </CopyrightText>
    </CopyrightWrapper>
  );
};

export default Copyright;

// NOTE: googleから警告があったため表示しないこと
{
  /* <Text mb={["5px", "10px"]}>運営メディア</Text>
<LinkText
  mb={["20px", "30px"]}
  href="https://tenjin-hifuka.com/kumacare"
  target="_blank"
>
  クマケアFinder
</LinkText> */
}
