import React from 'react';
import {IoIosArrowUp} from 'react-icons/io';
import ScrollUpButtonWrapper from './style';

interface DefualtProps {}

const ScrollUpButton: React.FC<DefualtProps> = props => {
  return (
    <ScrollUpButtonWrapper {...props}>
      <IoIosArrowUp />
    </ScrollUpButtonWrapper>
  );
};

export default ScrollUpButton;
