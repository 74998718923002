import * as React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import {
  typography,
  color,
  space,
  fontSize,
  fontFamily,
  width,
} from "styled-system";
import mq from "../../theme/media";

interface DefaultProps {
  fontSize?: {};
  width?: {};
  mt?: {};
  mb?: {};
  mr?: {};
  ml?: {};
  px?: {};
  py?: {};
  pt?: {};
  pb?: {};
}

const FooterMedia: React.FC<DefaultProps> = ({}) => {
  return (
    <Wrapper mb={[6, "100px"]}>
      <LinkText
        href="https://hairbook.jp/salons/43231/?utm_source=google&utm_medium=mybusiness&utm_campaign=website"
        target="_blank"
        mb={["5px", "10px"]}
      >
        再髪専門サロン Refero本山店
      </LinkText>
      <Text mt={["20px"]}>口コミ高評価★4.97【ご予約は1日3枠限定】</Text>
      <Text>〜大人女性の為の隠れ家再髮専門店リフェロ〜</Text>
      <Text>日本有数！医療提携の育毛促進サロンです</Text>
    </Wrapper>
  );
};

export default FooterMedia;

const Wrapper = styled("div")<DefaultProps>`
  position: relative;
  text-align: center;
  ${space}
`;

const Text = styled("p")<DefaultProps>`
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: #333;

  ${mq.down`
    font-size: 10px;
  `}

  ${space}
`;

const LinkText = styled("a")<DefaultProps>`
  display: block;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline !important;

  ${mq.down`
    font-size: 12px;
  `}

  &:hover,
  &:link,
  &:visited {
    color: #333;
  }

  &:hover {
    text-decoration: none !important;
  }
  ${space}
`;
